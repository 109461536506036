// Packages
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BookOpenIcon, PresentationChartLineIcon, ChatAlt2Icon } from "@heroicons/react/outline";

// Static Data
import { testmonials } from "~data/testmonials";

// Types
import type { SiteMetadata, GatsbyImagePropsMeta, Location } from "~types/Gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import type { Article } from "~types/Article";
import type { Product } from "~types/Shopify";

// Components
import Layout from "~components/layouts/Layout";
import Container from "~components/common/Container";
import Hero from "~components/sections/Hero";
import Grid from "~components/common/Grid";
import SubTitle from "~components/typography/SubTitle";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import Button from "~components/common/Button";
import CardBlog from "~components/cards/CardBlog";
import SectionBlockquote from "~components/sections/SectionBlockquote";
import Cta from "~components/sections/Cta";
import ElipseImageComposition from "~components/sections/elements/ElipseImageComposition";
import InvestInYourSelf from "~components/sections/InvestInYourSelf";
import Contact from "~components/sections/Contact";
import Ul from "~components/typography/Ul";
import H1 from "~components/typography/H1";
import FeatureIcon from "~components/features/FeatureIcon";
import VideoYouTube from "~components/common/VideoYouTube";
import Testmonials from "~components/sections/Testmonials";
import Logos from "~components/sections/Logos";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	seoImage: {
		facebook: GatsbyImagePropsMeta;
		twitter: GatsbyImagePropsMeta;
	};
	imageProfile: IGatsbyImageData;
	imageSpeedReadingWorkshop: IGatsbyImageData;
	imageVideo: IGatsbyImageData;
	indexArticles: {
		nodes: Article[];
	};
	products: {
		nodes: Product[];
	};
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	// Gatsby Images
	const imageSpeedReadingWorkshop = getImage(data.imageSpeedReadingWorkshop);

	const { nodes: articles }: { nodes: Article[] } = data.indexArticles;

	return (
		<Layout
			location={location}
			title="Daniel Thiel | Business Coach, Speaker und Trainer"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Hero element={<ElipseImageComposition imageData={data.imageProfile} alt="Daniel Thiel" />}>
				<SubTitle as="h1" className="mb-2">
					Investiere in Dich selbst
				</SubTitle>
				<H1 className="mb-4">Werde zur besten Version Deiner selbst und erkenne Dein Potenzial</H1>

				<Ul
					items={[
						"Dein Business Coach für Unternehmen, Führungskräfte und High-Performer*innen",
						"Dein Keynote Speaker für Deine Veranstaltungen",
						"Trainer für Speed Reading in Deinem Unternehmen",
					]}
					pretty
					className="mb-10"
				/>

				{/* <P className="mb-4">Das mache ich für Studenten, Selbständige und Unternehmen.</P>
				<P className="mb-4">
					Durch mein Coaching entfalten Selbständige ihr volles Potential und entwickeln unbekannte Stärken.
				</P>
				<P className="mb-4">
					Als Speaker nutzen Unternehmen meine Fähigkeiten der Kommunikation, Professionalität und den Charm des
					modernen Infotainments.
				</P>
				<P className="mb-4">
					Als Teilnehmer meiner Workshops studierst Duim Schnitt 2 bis 3 Semester weniger und kennst die Geheimnisse
					des SpeedReadings.
				</P> */}

				<Button to="/workshops/" text="Workshops" />
			</Hero>

			<Cta
				title="Buch mich jetzt für Dein Unternehmen!"
				description="Mit unseren Workshops kannst Du gezielt neue Techniken lernen und Deine Talente stärken."
				button={{ text: "Jetzt Workshop finden!", to: "/workshops/" }}
			/>

			<Logos />
			<Container>
				<Grid cols={3}>
					<FeatureIcon
						title="Firmen Trainer für Speed Reading"
						description="Lerne Speed Reading: 1-2 Semester weniger und mit besseren Leistungen studieren. Greife auf Deine volle Brainpower zu um einfacher, schneller und mehr Wissen aufbauen."
						icon={BookOpenIcon}
						color="blue"
						to="/workshops/"
					/>
					<FeatureIcon
						title="Business Coaching"
						description="Durch mein Coaching entfalten Selbständige und High Performer ihr volles Potential, entwickeln unbekannte Stärken und erreichen ihre Ziele."
						icon={PresentationChartLineIcon}
						color="blue"
						to="/coaching/"
					/>
					<FeatureIcon
						title="Keynote-Speaker"
						description="Als Speaker nutzen Unternehmen meine Fähigkeiten der zielführenden und rhetorischen Performance, Professionalität sowie meine Zuverlässigkeit und den Charm des modernen Infotainments."
						icon={ChatAlt2Icon}
						color="blue"
						to="/speaker/"
					/>
				</Grid>
			</Container>

			<Container>
				<H2 display className="mb-4 text-center">
					So enstand mein Business
				</H2>
				<VideoYouTube title="So enstand mein Business" videoId="mdo1yMrMCD8" videoCover={data.imageVideo} />
			</Container>

			<InvestInYourSelf />

			{/* <Container id="workshops">
				<div className="text-center">
					<SubTitle className="mb-2">Workshop buchen</SubTitle>
					<H2 display className="mb-16">
						Unser aktuelles Workshop-Angebot
					</H2>
				</div>

				{products.nodes.length > 0 ? (
					<Grid cols={3}>
						{products.nodes.map((product) => (
							<ProductCard key={product.title} product={product} />
						))}
					</Grid>
				) : (
					<Alert type="information" title="Keine Workshops gefunden" message={`Aktuell werden weitere Kurse geplant. Komme gerne später wieder oder buche unseren Newsletter für weitere Informationen.`} />
				)}
			</Container> */}

			<Container>
				<Grid cols={2} items="center">
					<div className="text-center">
						{imageSpeedReadingWorkshop ? (
							<GatsbyImage image={imageSpeedReadingWorkshop} alt="" className="rounded" />
						) : null}
					</div>
					<div>
						<SubTitle className="mb-2">Speed Reading Workshop</SubTitle>
						<H2 className="mb-4">Schneller, klüger und nachhaltiger lesen</H2>

						<P className="mb-3">
							Es ist mehr Vermögen und Wohlstand mit unserem Verstand entstanden, als Gold und andere Edelmetalle aus
							dem Boden gefördert worden sind.{" "}
						</P>
						<P className="mb-3">
							Wir leben in einem Zeitalter, in dem die richtigen Informationen den entscheidenden Unterschied zwischen
							dem Erreichen und Nichterreichen gesetzter Ziele, Träume und Wünsche ausmachen. Wir stehen am Beginn eines
							Zeitalters, in dem mit Deiner DNA Deine eigenen Organe im 3D-Drucker gedruckt werden können. Es wird Zeit,
							dass auch wir unsere Fähigkeiten auf ein neues Level bringen.
						</P>
						<P className="mb-3">
							Unsere schnelle und dynamische Zeit erfordert eine Anpassung unserer Fähigkeiten im kognitiven Bereich.
							Alles ist trainierbar.
						</P>
						<P className="mb-3">
							In unserem Workshop erlernst Du ein neues Level des Lesens mit mehr Textverständnis in der Hälfte der
							sonst benötigten Zeit.
						</P>
						<P className="mb-3">
							Dadurch erschaffst Du Dir Vorteile, um schneller Wissen aufzubauen und mehr zu verstehen. Du kannst Dich
							vom Wettbewerb absetzen, Deine eigenen Ziele und Wünsche schneller und fokussierter erreichen. So hast Du
							mehr Glück, Spaß und Zeit für die schönen Dinge im Leben - und das in kürzerer Zeit.
						</P>
						<P className="mb-10">
							Melde Dich an und sei gespannt was Dich erwartet. Es ist Zeit in Dich und Deine Fähigkeiten zu
							investieren.{" "}
						</P>
						<Button to="/workshops/speed-reading/" text="Zu Speed Reading Workshop" type="secondary" />
					</div>
				</Grid>
			</Container>

			<SectionBlockquote
				text="Es macht immer mehr Sinn eine Stunde über sein Geld nachzudenken, als einen Monat dafür zu arbeiten."
				author="John D. Rockefeller"
			/>

			<Container>
				<div className="mb-5 flex items-end">
					<div className="flex-1">
						<SubTitle className="mb-2">Unser Blog</SubTitle>
						<H2 display className="mb-4">
							Letzten Blog-Beiträgen
						</H2>
					</div>
					<div>
						<Button to="/blog/" text="Alle Beiträge" />
					</div>
				</div>
				<Grid cols={3}>
					{articles.map((article) => (
						<CardBlog key={article.id} article={article} />
					))}
				</Grid>
			</Container>

			<Testmonials data={testmonials} />

			<Cta
				title="Jetzt Workshop finden!"
				description="Mit unseren Workshops kannst Du gezielt neue Techniken lernen und Deine Talente stärken."
				button={{ text: "Jetzt Workshop finden!", to: "/workshops/" }}
			/>

			<Contact />
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		imageProfile: file(relativePath: { eq: "components/hero/daniel-thiel.png" }) {
			childImageSharp {
				gatsbyImageData(quality: 100)
			}
		}

		imageSpeedReadingWorkshop: file(relativePath: { eq: "pages/index/speed-reading-workshop.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageVideo: file(relativePath: { eq: "pages/index/youtube-video-cover.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		indexArticles: allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
			nodes {
				dateGmt(formatString: "DD.MM.YYYY")
				title
				uri
				excerpt
				id
				featuredImage {
					node {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(aspectRatio: 1.5)
							}
						}
					}
				}
			}
		}
	}
`;
